import React, {useState} from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

const Wordpress = () => {


  return(
  <Layout className='bg-slate-50'>
    <SEO title={'Brandon Jewell'} description={"I've spent the last decade designing, developing, and problem solving for startups, unicorns, and other businesses."}/>
    <div className='max-w-[750px] mx-auto px-4 py-16'>

    <h1 className='h1 mb-8'>To Copysmith...</h1>
    <p className='p-lg mt-0 mb-16'>
    	A little taste of some design noodling on a friday night, and a demo of the potential that I see in the copysmith product family. A main lockup, a rebrand / logo concept, and a content & blog section.
    </p>

    <StaticImage className='w-full max-w-[750px] mb-[50px] h-auto' src="../images/describely-old.png" />
    <StaticImage className='w-full max-w-[750px] mb-[50px] h-auto' src="../images/describely-new.png" />

    <StaticImage className='w-full max-w-[750px] mb-[50px] h-auto' src="../images/frase-old.png" />
    <StaticImage className='w-full max-w-[750px] mb-[50px] h-auto' src="../images/frase-new.png" />

    <StaticImage className='w-full max-w-[750px] mb-[50px] h-auto' src="../images/rytr-old.png" />
    <StaticImage className='w-full max-w-[750px] mb-[50px] h-auto' src="../images/rytr-new.png" />
    </div>









  </Layout>
)}

export default Wordpress